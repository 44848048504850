import CryptoJS from "crypto-js";
import { secretKey } from "../utils/api/ApiUrl";

export const encryptUserId = (userId) => {
  // Encrypt the user ID using AES
  const ciphertext = CryptoJS.AES.encrypt(
    userId.toString(),
    secretKey
  ).toString();

  // Return the encrypted ID, truncated to 32 characters if necessary
  return ciphertext;
};

// Function to decrypt user ID
export const decryptUserId = (encryptedId) => {
  try {
    // Decrypt the encrypted ID using AES
    const bytes = CryptoJS.AES.decrypt(encryptedId, secretKey);

    // Convert bytes to the original string
    const originalId = bytes.toString(CryptoJS.enc.Utf8);

    return originalId;
  } catch (error) {
    console.error("Failed to decrypt the user ID:", error);
    return null;
  }
};
